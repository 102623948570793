class Request {
  constructor({
    cost,
    url,
    httpStatus,
    bizStatus,
    bizMessage,
    requestId = '',
    responseHeaders = {},
  }) {
    this.url = url;
    this.cost = cost;
    this.http_status = httpStatus || 0;
    this.biz_status = bizStatus;
    this.biz_message = bizMessage;
    this.request_id = requestId;
    this.response_headers = JSON.stringify(responseHeaders);
  }

  get json() {
    return { ...this };
  }
}

export default Request;
