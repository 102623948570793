class Network {
  constructor({
    downlink = 0,
    effectiveType = -1,
    rtt = '',
    saveData = '',
  }) {
    this.downlink = downlink;
    this.effective_type = effectiveType;
    this.rtt = rtt;
    this.save_data = saveData;
  };

  get json() {
    return { ...this };
  }
};

export default Network;