import { isBrowser } from '../utils/env';
import BrowserAdaptor from './adaptors/browser';

const getUploader = () => {
  if (isBrowser()) {
    return BrowserAdaptor;
  }
};


export default getUploader;
