class PerMemory {
  constructor({
    jsHeapSizeLimit = -1,
    totalJSHeapSize = -1,
    usedJSHeapSize = -1,
  }) {
    this.js_heap_size_limit = jsHeapSizeLimit;
    this.total_js_heap_size = totalJSHeapSize;
    this.used_js_heap_size = usedJSHeapSize;
  };

  get json() {
    return { ...this };
  }
};

export default PerMemory;
