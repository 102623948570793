class Paint {
  constructor({
    name = '',
    duration = 0,
    startTime = null,
    renderTime = null,
    loadTime = null,
    resourceSize = 0,
    resourceUrl = '',
    elementPath = '',
  }) {
    this.name = name;
    this.duration = duration;
    this.start_time = startTime;
    this.render_time = renderTime;
    this.load_time = loadTime;
    this.resource_size = resourceSize;
    this.resource_url = resourceUrl;
    this.element_path = elementPath;
  };

  get json() {
    return { ...this };
  }
};

export default Paint;
