import { isBrowser } from './env';

const crypto = window?.crypto || window?.msCrypto;

function getRandomValues(buffer) {
  crypto.getRandomValues(buffer);
  return buffer;
}

function generateHexSegment(length) {
  return Array.from(getRandomValues(new Uint8Array(length)))
    .map(b => b.toString(16).padStart(2, '0'))
    .join('');
}

function generateCryptoUUID() {
  return [
    generateHexSegment(4),
    generateHexSegment(2),
    Math.round(Math.random() * 9) + generateHexSegment(2).substr(1),
    (8 + (Math.random() * 4 | 0)).toString(16) + generateHexSegment(1),
    generateHexSegment(6),
  ].join('-');
};

function generateRandomUUID(terminalId) {
  return (Date.now() + new Function(`return 0x${terminalId.replace('-', '')}`)()).toString(16);
}

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}


export default function uuid(terminalId) {
  return crypto ? generateCryptoUUID() : generateRandomUUID(terminalId);
}


