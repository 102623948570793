import { isBrowser } from '@/lib/utils/env';
class UserLogger {
  apply(logger) {
    this.logger = logger;
    this.watch();
  }

  watch() {
    if (isBrowser()) {
      this.logger.track({
        data: {
          name: 'enterPage',
        },
      });

      window.addEventListener('beforeunload', () => {
        this.logger.track({
          data: {
            name: 'closeOrLeavePage',
          },
        }, { forceUpload: true });
      });
    }
  }
};

export default UserLogger;
