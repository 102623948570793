
import Network from '@/models/network';

class NetworkLogger {
  apply(logger) {
    this.logger = logger;
    if (window?.navigator?.connection) {
      this.logEvent(window.navigator.connection);
    }
  }

  logEvent(data = {}) {
    this.logger.track({
      type: 'network',
      data: new Network(data).json,
    });
  }
};

export default NetworkLogger;
