class PerNavigation {
  constructor({
    startTime = null,
    blankCost = 0,
    redirectCost = 0,
    dnsCost = 0,
    tcpCost = 0,
    reqTime = null,
    resTime = null,
    resCost = 0,
    domTime = null,
    loadTime = null,
    interactiveTime = null,
  }) {
    this.start_time = startTime;
    this.blank_cost = blankCost;
    this.redirect_cost = redirectCost;
    this.dns_cost = dnsCost;
    this.tcp_cost = tcpCost;
    this.req_time = reqTime;
    this.res_time = resTime;
    this.res_cost = resCost;
    this.dom_time = domTime;
    this.load_time = loadTime;
    this.interactive_time = interactiveTime;
  }

  get json() {
    return { ...this };
  }
}

export default PerNavigation;
