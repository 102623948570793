class IndexDbAdaptor {
  constructor({ dbName, storeName } = {}) {
    this.dbName = dbName;
    this.storeName = storeName;
    this.db = null;
    this.init();
  }

  init() {
    const request = indexedDB.open(this.dbName);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(this.storeName)) {
        db.createObjectStore(this.storeName, { keyPath: 'id', autoIncrement: true });
      }
    };

    request.onsuccess = (event) => {
      this.db = event.target.result;
    };

    request.onerror = (event) => {
      reject(`数据库打开失败: ${event.target.errorCode}`);
    };
  }

  _getTransactionStore(mode) {
    const transaction = this.db.transaction([this.storeName], mode);
    const store = transaction.objectStore(this.storeName);
    return {
      transaction,
      store,
    };
  }

  count() {
    return new Promise((resolve, reject) => {
      const { store } = this._getTransactionStore('readonly');
      const request = store.count();
      request.onsuccess = () => {
        resolve(request.result);
      };
      request.onerror = reject;
    });
  }

  add(items) {
    return new Promise((resolve, reject) => {
      const { store, transaction } = this._getTransactionStore('readwrite');
      const addItems = items.length ? items : [items];
      addItems.forEach((item) => store.add(item));
      transaction.oncomplete = resolve;
      transaction.onerror = reject;
    });
  }

  get(key) {
    return new Promise((resolve, reject) => {
      const { store } = this._getTransactionStore('readonly');
      const request = store.get(key);

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = reject;
    });
  }

  getNumItems(num) {
    return new Promise((resolve, reject) => {
      const { store } = this._getTransactionStore('readonly');
      const request = store.openCursor();
      const results = [];
      let count = 0;
      request.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor && count < num) {
          results.push(cursor.value);
          count++;
          cursor.continue();
        } else {
          resolve(results);
        }
      };
      request.onerror = reject;
    })
  }

  // 更新数据
  update(item) {
    return new Promise((resolve, reject) => {
      const { store } = this._getTransactionStore('readwrite');
      const request = store.put(item);
      request.onsuccess = resolve;
      request.onerror = reject;
    });
  }

  _remove(store, id) {
    return new Promise((resolve, reject) => {
      const request = store.delete(id);
      request.onsuccess = resolve;
      request.onerror = reject;
    });
  }

  remove(ids = []) {
    const { store, transaction } = this._getTransactionStore('readwrite');
    const deleteIds = ids.length ? ids : [ids];
    const stacks = deleteIds.map((id) => this._remove(store, id));
    return Promise.all(stacks)
      .catch(() => transaction.abort());
  }

  clear() {
    return new Promise((resolve, reject) => {
      const { store } = this._getTransactionStore('readwrite');
      const request = store.clear();
      request.onsuccess = resolve;
      request.onerror = reject;
    });
  }
}

export default IndexDbAdaptor;
