import { isBrowser } from '@/lib/utils/env';
import { shortUrl } from '@/lib/utils/url';

import PerNavigation from '@/models/perNavigation';
import PerMemory from '@/models/perMemory';
import PerResource from '@/models/perResource';
import Paint from '@/models/paint';

import { getXPath } from '@/lib/utils/dom';

const loggerType = 'performance';
const ignoreCodePrefix = ['2', '3'];
const ignoreTypes = ['xmlhttprequest', 'fetch'];

class PerformanceLogger {
  apply(logger) {
    this.logger = logger;

    this.watch();
  }

  watch() {
    if (isBrowser()) {
      window.addEventListener('load', () => {
        if (typeof PerformanceObserver === 'function') {
          const performanceObserver = new PerformanceObserver(list => {
            list.getEntries().forEach(entry => {
              this.handleResource(entry);
            });
          });
          performanceObserver.observe({ entryTypes: ['resource', 'largest-contentful-paint'] });
        }

        const { timing, memory } = performance;
        const times = this.calculateTimes(timing);
        this.logger.track({
          type: loggerType,
          subType: 'navigation',
          data: new PerNavigation(times).json,
        });
        this.logger.track({
          type: loggerType,
          subType: 'memory',
          data: new PerMemory(memory).json,
        });
        performance.getEntries().forEach((entry) => {
          this.handleResource(entry);
        });
      });
    }
  }

  handleResource(resource) {
    const { resourceCostThrottle } = this.logger.options;
    const { name, duration, entryType, initiatorType } = resource;
    if (ignoreTypes.includes(initiatorType)) {
      return;
    }
    switch (entryType) {
      case 'paint':
      case 'largest-contentful-paint': {
        const { startTime, renderTime, loadTime, size, url, element } = resource;
        this.logger.track({
          type: 'paint',
          data: new Paint({
            name: name || entryType,
            duration,
            startTime: startTime ? Math.round(startTime) : null,
            renderTime: renderTime ? Math.round(renderTime) : null,
            loadTime: loadTime ? Math.round(loadTime) : null,
            resourceSize: size,
            resourceUrl: shortUrl(url),
            elementPath: element ? getXPath(element) : undefined,
          }).json,
        });
        break;
      }
      case 'resource': {
        const { renderBlockingStatus, transferSize, responseStatus } = resource;
        const isBeyondThrottle = duration >= resourceCostThrottle;
        const isIcoResource = name.includes('.ico');
        const isIllegalResponse = responseStatus &&
          !ignoreCodePrefix.includes(String(responseStatus)[0]);
        if (isBeyondThrottle || isIcoResource || isIllegalResponse) {
          const times = this.calculateTimes(resource);
          this.logger.track({
            type: loggerType,
            subType: 'resource',
            data: new PerResource({
              ...times,
              resourceSize: transferSize,
              resourceUrl: shortUrl(name),
              resourceCost: Math.round(duration),
              resourceBlockingStatus: renderBlockingStatus,
              httpStatus: responseStatus
            }).json,
          });
        }
        break;
      }
      default:
        break;
    }
  }

  calculateTimes(timing) {
    const {
      navigationStart,
      redirectStart,
      redirectEnd,
      domainLookupStart,
      domainLookupEnd,
      connectStart,
      connectEnd,
      requestStart,
      responseStart,
      responseEnd,
      domComplete,
      loadEventEnd,
      domInteractive,
    } = timing;
    const startTime = navigationStart;
    const blankCost = Math.ceil(responseStart - navigationStart);
    const redirectCost = Math.ceil(redirectEnd - redirectStart);
    const dnsCost = Math.ceil(domainLookupEnd - domainLookupStart);
    const tcpCost = Math.ceil(connectEnd - connectStart);
    const reqTime = requestStart;
    const resTime = responseEnd;
    const resCost = Math.ceil(responseEnd - responseStart);
    const domTime = domComplete;
    const loadTime = loadEventEnd;
    const interactiveTime = domInteractive;
    return {
      startTime,
      blankCost,
      redirectCost,
      dnsCost,
      tcpCost,
      reqTime,
      resTime,
      resCost,
      domTime,
      loadTime,
      interactiveTime,
    };
  }
}

export default PerformanceLogger;
