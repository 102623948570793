class Error {
  constructor({
    colno = -1,
    lineno = -1,
    message = '',
    filename = '',
    stack = '',
  }) {
    this.colno = colno;
    this.lineno = lineno;
    this.message = message;
    this.filename = filename;
    this.stack = stack;
  };

  get json() {
    return { ...this };
  }
};

export default Error;
