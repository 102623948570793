import { isBrowser } from '@/lib/utils/env';

import Error from '@/models/error';

class ErrorLogger {
  apply(logger) {
    this.logger = logger;
    this.watch();
  }

  watch() {
    if (isBrowser()) {
      window.addEventListener('error', (e) => {
        const {
          colno,
          lineno,
          message,
          filename,
        } = e;
        const {
          stack,
        } = e.error;
        this.logger.track({
          type: 'error',
          data: new Error({
            colno,
            lineno,
            filename,
            message: JSON.stringify(message),
            stack: JSON.stringify(stack),
          }),
        });
      });

      window.addEventListener('unhandledrejection', (e) => {
        let message = e.reason, stack = {}, data = {};
        if (e.reason instanceof window.Error) {
          message = e.reason.message;
          stack = e.reason.stack;
          data = e.reason;
        };
        this.logger.track({
          type: 'error',
          data: new Error(Object.assign(data, {
            message: JSON.stringify(message),
            stack: JSON.stringify(stack),
          })),
        });
      });
    }
  }
};

export default ErrorLogger;
