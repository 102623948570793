import { detectIncognito } from 'detectincognitojs';
import { md5 } from 'js-md5';
import { getCookie } from './cookie';
import { shortUrl } from './url';

export const isBrowser = () => {
  return typeof window !== 'undefined';
};

const _getBrowserMode = async () => {
  const { isPrivate } = await detectIncognito();
  if (isPrivate) {
    return 'incognito';
  }
  return 'normal';
};


export const getMode = async () => {
  if (isBrowser()) {
    return await _getBrowserMode();
  }
  return '';
}

const _getBrowserOSType = () => {
  let userAgent = window.navigator.userAgent;
  let platform = window.navigator.platform;
  let os = null;

  if (/Mac/i.test(platform)) {
    os = 'mac-os';
  } else if (/Win/i.test(platform)) {
    os = 'windows';
  } else if (/Linux/i.test(platform)) {
    os = 'linux';
  } else if (/Android/i.test(userAgent)) {
    os = 'android';
  } else if (/iOS/i.test(userAgent) || /iPhone|iPad|iPod/i.test(platform)) {
    os = 'ios';
  } else if (/CrOS/i.test(userAgent)) {
    os = 'chrome-os';
  } else {
    os = 'other';
  }

  return os;
}

export const getOsType = () => {
  if (isBrowser()) {
    return _getBrowserOSType();
  }
  return '';
};

const _getBrowserDeviceType = () => {
  let userAgent = navigator.userAgent;

  if (/Mobi|Android/i.test(userAgent)) {
    return 'mobile';
  } else if (/Tablet|iPad/i.test(userAgent)) {
    return 'pad';
  } else {
    return 'pc';
  }
}

export const getDeviceType = () => {
  if (isBrowser()) {
    return _getBrowserDeviceType();
  }
  return '';
};

const getCanvasFingerprint = () => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const t = '<canvas> id';

  canvas.width = 158;
  canvas.height = 30;

  ctx.textBaseline = 'top';
  ctx.font = '14px Arial';
  ctx.textBaseline = 'alphabetic',
  ctx.fillStyle = '#069';
  ctx.fillRect(90, 2, 62, 60);
  ctx.fillStyle = '#069';
  ctx.fillText(t, 3, 25);
  ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
  ctx.fillText(t, 4, 17);

  const dataUrl = canvas.toDataURL();
  const imgData = atob(dataUrl.split(',')[1]);
  const ret = [];
  for (let i = 0; i < imgData.length; i++) {
    ret[i] = imgData.charCodeAt(i);
  }

  return md5(ret);
}

export const getTerminalId = () => {
  if (isBrowser()) {
    return getCanvasFingerprint();
  }
  return '';
};

export const getAgentType = () => {
  if (isBrowser()) {
    return navigator.userAgent;
  }
  return '';
}

const _getBrowserVersion = () => {
  const ua = navigator.userAgent;
  let temp;
  let version = '';

  let match = ua.match(/(firefox|msie|trident|chrome|safari|opr|edg|edge(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(match[1])) {
    temp = /\brv[ :]+(\d+)/g.exec(ua) || [];
    version = temp[1] || '';
  } else if (match[1] === 'Chrome') {
    temp = ua.match(/\b(OPR|Edg|Edge)\/(\d+)/);
    if (temp !== null) {
      version = temp[2];
    } else {
      version = match[2];
    }
  } else {
    match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((temp = ua.match(/version\/(\d+)/i)) !== null) {
      match.splice(1, 1, temp[1]);
    }
    version = match[1];
  }

  return version;
};

export const getAgentVersion = () => {
  if (isBrowser()) {
    return _getBrowserVersion();
  }
  return '';
}


export const getPageUrl = () => {
  if (isBrowser()) {
    return shortUrl(location.href);
  }
  return '';
}

export const getHost = () => {
  if (isBrowser()) {
    return location.host;
  }
  return '';
}


const getQueryValue = (name) => {
  const reg = RegExp(`${name}=([^&$]*)`);
  if (reg.test(location.search)) {
    return decodeURIComponent(RegExp.$1);
  }
  return '';
}

const getUrlValue = (name) => {
  const reg = RegExp(`${name}/([^/$]*)`);
  if (reg.test(location.href)) {
    return decodeURIComponent(RegExp.$1);
  }
  return '';
}


export const getContextParams = () => {
  const ret = {};
  if (isBrowser()) {
    const projectId = getQueryValue('virtualProjectGuid');
    if (projectId) {
      ret.projectId = projectId;
    }

    const collectionId = getUrlValue('collection') || getQueryValue('collection_uid');
    if (collectionId) {
      ret.collectionId = collectionId;
    }

    const userId = getCookie('AS_UID');
    if (userId) {
      ret.userId = userId;
    }

    const sessionId = getCookie('customerSessionGuid');
    if (sessionId) {
      ret.sessionId = sessionId;
    }

    const installId = getCookie('_as_install_id');
    if (installId) {
      ret.installId = installId;
    }
  }

  return ret;
}
