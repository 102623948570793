class SendLog {
  constructor({
    osType = '',
    deviceType = '',
    agentType = '',
    agentVersion = '',
    agentMode = '',
    terminalId = '',
    events = [],
  }) {
    this.protocol_version = '1.0';
    this.os_type = osType; // windows|linux|mac|ios|android
    this.device_type = deviceType; // pc|mobile|pad
    this.agent_type = agentType; // native|browser|webview|minip|react-native
    this.agent_mode = agentMode; // normal / incognito
    this.agent_version = agentVersion; // native-bin-0|browser-chrome-116|minip-weixin-0|minip-zhifubao-0
    this.terminal_id = terminalId;
    this.events = [];
    this.parseEvents(events);
  }

  parseEvents(events = []) {
    events.forEach((event) => {
      const { id, ...data } = event;
      this.events.push({
        ...data,
        send_time: Date.now(),
      });
    });
  }

  get json() {
    return { ...this };
  }
}


export default SendLog;
