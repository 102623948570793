class BrowserAdaptor {
  constructor({ url }) {
    this.url = url;
  }

  sendData(data) {
    const fetchFn = window.__oFetch__ || window.fetch;
    return fetchFn(this.url, {
      method: 'POST',
      headers: {
        contentType: 'application/json',
      },
      keepalive: true,
      body: JSON.stringify(data),
    });
  }

  send(data) {
    return this.sendData(data);
  }
};

export default BrowserAdaptor;
