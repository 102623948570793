export function getXPath(element) {
    if (element.id !== '') {
      return `/[@id='${element.id}']`;
    }

    if (element === document.body) {
      return '/html/body';
    }

    let index = 0;
    const siblings = element.parentNode.childNodes;

    for (let i = 0; i < siblings.length; i++) {
      const sibling = siblings[i];
      if (sibling === element) {
        return `${getXPath(element.parentNode)}/${element.tagName.toLowerCase()}[${index + 1}]`;
      }
      if (sibling.nodeType === 1 && sibling.tagName === element.tagName) {
        index++;
      }
    }
  }
