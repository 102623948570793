class Event {
  constructor({
    type = '', // msg|exception|error|performance|user_behav|api
    subType = '',
    appId = '',
    pageUrl = '',
    userId = '',
    moduleId = '',
    projectId = '',
    collectionId = '',
    sessionId = '',
    installId = '',
    bizlineId = '',
    host = '',
    extraData = {},
    data = {},
  }) {
    this.type = type;
    this.sub_type = subType ;
    this.app_id = appId;
    this.page_url = pageUrl;
    this.user_id = userId;
    this.module_id = moduleId;
    this.project_id = projectId;
    this.collection_id = collectionId;
    this.session_id = sessionId;
    this.install_id = installId;
    this.bizline_id = bizlineId;
    this.extra_data = extraData;
    this.host = host;
    this.data = data;
    this.log_time = Date.now();
  }

  get json() {
    return { ...this };
  }
}

export default Event;
