import { isBrowser } from '@/lib/utils/env';
import uuid from '@/lib/utils/uuid';
import { shortUrl } from '@/lib/utils/url';

import Request from '@/models/request';
import Error from '@/models/error';

class RequestLogger {
  apply(logger) {
    this.logger = logger;
    this.watch();
  }

  watch() {
    if (isBrowser()) {
      this.overrideXhr();
      this.overrideFetch();
    }
  }

  logEvent(data = {}) {
    this.logger.track({
      type: 'api',
      data: new Request(data).json,
    });
  }

  checkIsExcludeUrl(url) {
    const { options, uploader } = this.logger;
    const { url: logUrl } = uploader;
    const { excludePaths, } = options;
    const isLocal = /https?:\/\/(localhost|127.0.0.1).*/.test(url);
    if (isLocal) {
      return true;
    }
    return url.includes(logUrl) || excludePaths.some((path) => {
      return url.includes(path);
    });
  }

  checkIsAddTraceHeader(url) {
    const { options, uploader } = this.logger;
    const { url: logUrl } = uploader;
    const { allowedDomains } = options;
    return url.includes(logUrl) || allowedDomains.some((domain) => {
      return url.includes(domain);
    });
  }

  overrideXhr() {
    const { terminalId } = this.logger.globalParams;
    const _this = this;
    const originalXhr = window.XMLHttpRequest;
    window.__oXMLHttpRequest_ = originalXhr;

    function customXhr() {
      const xhr = new originalXhr();
      let startTime, endTime, requestUrl, requestMethod, isExcludeUrl;

      const requestId = uuid(terminalId);

      const originalOpen = xhr.open;

      const onComplete = () => {
        if (isExcludeUrl) {
          return;
        }
        endTime = performance.now();
        const cost = Math.ceil(endTime - startTime);
        const url = shortUrl(requestUrl);
        const httpStatus = xhr.status;
        let bizStatus = -1, bizMessage = '', responseHeaders = {};
        try {
          const ossId = xhr.getResponseHeader('x-oss-request-id');
          const amzId = xhr.getResponseHeader('x-amz-request-id');
          const retCode = xhr.getResponseHeader('x-as-ret-code');
          const retMessage = xhr.getResponseHeader('x-as-ret-message');
          if (ossId) {
            responseHeaders['x-oss-request-id'] = ossId;
          }
          if (amzId) {
            responseHeaders['x-amz-request-id'] = amzId;
          }
          if (retCode) {
            bizStatus = retCode;
          }
          if (retMessage) {
            bizMessage = retMessage;
          }
        } catch (e) { };
        _this.logEvent({
          cost,
          url,
          httpStatus,
          bizStatus,
          bizMessage,
          requestId,
          responseHeaders,
        });
      }

      xhr.open = (method, url, ...rest) => {
        requestUrl = url;
        requestMethod = method;
        isExcludeUrl = _this.checkIsExcludeUrl(requestUrl);
        originalOpen.call(xhr, method, url, ...rest);
        const isAddTraceHeader = _this.checkIsAddTraceHeader(requestUrl);
        if (isAddTraceHeader) {
          xhr.setRequestHeader('X-AS-REQUEST-ID', requestId);
        }
      };

      xhr.addEventListener('loadstart', () => {
        startTime = performance.now();
      });

      xhr.addEventListener('load', () => {
        if (requestMethod === 'PUT') {
          onComplete();
        }
      });

      xhr.addEventListener('error', () => {
        if (requestMethod === 'PUT') {
          onComplete();
        }
      });

      xhr.addEventListener('loadend', () => {
        if (requestMethod === 'PUT') {
          return;
        }
        onComplete();
      });

      return xhr;
    }

    for (let attr in originalXhr) {
      if (originalXhr.hasOwnProperty(attr)) {
        customXhr[attr] = originalXhr[attr];
      }
    }

    window.XMLHttpRequest = customXhr;
  }

  overrideFetch() {
    const { terminalId } = this.logger.globalParams;
    const _this = this;
    const originalFetch = window.fetch;
    window.__oFetch__ = originalFetch;
    window.fetch = (url, options = {}) => {
      const startTime = performance.now();
      let endTime;
      let cost = 0, httpStatus = 0, bizStatus = -1, bizMessage = '', responseHeaders = {};
      const isExcludeUrl = _this.checkIsExcludeUrl(url);
      if (isExcludeUrl) {
        return originalFetch(url, options);
      }

      return new Promise((resolve, reject) => {
        const requestId = uuid(terminalId);

        const onComplete = () => {
          _this.logEvent({
            cost,
            url: shortUrl(url),
            httpStatus,
            bizStatus,
            bizMessage,
            requestId,
            responseHeaders,
          });
        }

        const isAddTraceHeader = _this.checkIsAddTraceHeader(url);
        const headers = isAddTraceHeader ? Object.assign({}, options.headers || {}, {
          'X-AS-REQUEST-ID': requestId,
        }) : Object.assign({}, options.headers || {})

        originalFetch(url, {
          ...options,
          headers,
        })
          .then((res) => {
            resolve(res);
            endTime = performance.now();
            cost = Math.ceil(endTime - startTime);
            httpStatus = res.status;
            const ossId = res.headers.get('x-oss-request-id');
            const amzId = res.headers.get('x-amz-request-id');
            const retCode = res.headers.get('x-as-ret-code');
            const retMessage = res.headers.get('x-as-ret-message');
            if (ossId) {
              responseHeaders['x-oss-request-id'] = ossId;
            }
            if (amzId) {
              responseHeaders['x-amz-request-id'] = amzId;
            }
            if (retCode) {
              bizStatus = retCode;
            }
            if (retMessage) {
              bizMessage = retMessage;
            }
            onComplete();
          })
          .catch((err) => {
            try {
              const {
                message,
                stack,
              } = err;
              this.logger.track({
                type: 'error',
                data: new Error({
                  message: `${message}: ${shortUrl(url)}`,
                  stack: JSON.stringify(stack),
                }),
              });
            } catch (e) { };
            if (isExcludeUrl) {
              return;
            }
            onComplete();
          });
      });
    };
  }
};

export default RequestLogger;
