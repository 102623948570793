import getStorage from '../lib/storage';
import getUploader from '../lib/uploader';
import { generateUUID } from '@/lib/utils/uuid';

import SendLog from '@/models/sendLog';
import Event from '@/models/event';

import ErrorLogger from '@/plugins/error';
import PerformanceLogger from '@/plugins/performance';
import RequestLogger from '@/plugins/request';
import NetworkLogger from '@/plugins/network';
import UserLogger from '@/plugins/user';

import {
  getMode,
  getOsType,
  getDeviceType,
  getTerminalId,
  getAgentType,
  getAgentVersion,
  getPageUrl,
  getHost,
  getContextParams,
} from '@/lib/utils/env';

const defaultPlugins = [
  ErrorLogger,
  PerformanceLogger,
  RequestLogger,
  NetworkLogger,
  UserLogger,
];

const DEFAULT_OPTIONS = {
  maxCountPerBatch: 20,
  resourceCostThrottle: 3000,
  eventsAddInterval: 800,
  scheduleSendInterval: 30000,
  allowedDomains: [],
  excludePaths: [],
  storage: {
    policy: 'indexdb',
    dbName: 'big-logger',
    storeName: 'events',
  },
  uploader: {
    url: '/log',
  },
};

export default class bigLogger {
  constructor(options = {}) {
    this.options = Object.assign({}, DEFAULT_OPTIONS, options);
    this.globalParams = {};
    this.baseParams = {};
    this.storage = {};
    this.uploader = {};
    this.isUploading = false;
    this.terminalId = getTerminalId();

    this._events_cache = [];
    this._events_add_timer = null;

    this._schedule_send_timer = null;

    this.init();
  }

  init() {
    const { configUrl } = this.options;
    let serverOptions = {};
    let isEnabled = false;

    if (configUrl && typeof XMLHttpRequest === 'function') {
      try {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', configUrl, false);
        xhr.send(`{ terminalId: ${this.terminalId} }`);
        serverOptions = JSON.parse(xhr.responseText);
        isEnabled = serverOptions.isEnabled;
        this.setParams(serverOptions);
      } catch (e) { };
    }

    if (isEnabled) {
      this.storage = new (getStorage(this.options?.storage?.policy))(this.options.storage);
      this.uploader = new (getUploader())(this.options.uploader);
      this.installPlugins();
      this.listenWindowLoaded();
    }

  }

  listenWindowLoaded() {
    window.addEventListener('load', () => {
      window._IS_WINDOW_LOADED = true;
      this.sendIfNeed();
      this.scheduleSend();
    });
  }

  installPlugins() {
    const customPlugins = this.options.plugins || [];
    const allPlugins = [...defaultPlugins, ...customPlugins];
    const loggerInstance = this;
    allPlugins.forEach((plugin) => {
      const pluginInstance = new plugin();
      if (typeof pluginInstance?.apply === 'function') {
        pluginInstance.apply(loggerInstance);
      }
    });
  }

  setParams(params = {}) {
    this.options = Object.assign(this.options, params);
    this.setGlobalParams(this.options);
    this.setBaseParams(this.options);
  }

  async setGlobalParams(params) {
    const { agentType = '', osType = '', deviceType = '', agentVersion = '' } = params;
    this.globalParams = {
      agentMode: await getMode(),
      terminalId: this.terminalId,
      osType: getOsType() || osType,
      agentType: getAgentType() || agentType,
      deviceType: getDeviceType() || deviceType,
      agentVersion: getAgentVersion() || agentVersion,
    };
  }

  setBaseParams(params) {
    const { appId = '', bizlineId = '' } = params;
    this.baseParams = {
      appId,
      bizlineId,
    };
  }

  getEvent(event) {
    return new Event({
      id: generateUUID(),
      ...this.baseParams,
      host: getHost(),
      pageUrl: getPageUrl(),
      type: 'user_behav',
      ...getContextParams(),
      ...event,
    }).json;
  }

  getSendMsg(events) {
    return new SendLog({
      ...this.globalParams,
      events,
    }).json;
  }

  send(event) {
    const msg = this.getSendMsg([event]);;
    this.uploader
      .send(msg);
  }

  batchSend(events = []) {
    return new Promise((resolve, reject) => {
      const msg = this.getSendMsg(events);
      requestIdleCallback(() => {
        this.uploader
          .send(msg)
          .then(resolve)
          .catch(reject);
      });
    });
  }

  scheduleSend() {
    this._schedule_send_timer = setTimeout(() => {
      clearTimeout(this._schedule_send_timer);
      this.sendIfNeed(this.scheduleSend.bind(this), false);
    }, this.options.scheduleSendInterval);
  }

  sendIfNeed(cb, isCheckSendThrottle = true) {
    if (typeof window !== 'undefined' && !window._IS_WINDOW_LOADED) {
      return;
    }
    if (this.isUploading) {
      cb && cb();
      return;
    }
    this.isUploading = true;
    requestIdleCallback(async () => {
      try {
        // 如果达到了单次上传的阈值或者当前是定时上传，则上传
        const isNeedUpload = !isCheckSendThrottle || (await this.checkNeedUpload());
        if (isNeedUpload) {
          const events = await this.storage.getNumItems(this.options.maxCountPerBatch);
          if (events.length) {
            await this.batchSend(events);
            const deleteIds = events.map((event) => event.id);
            this.storage.remove(deleteIds);
          }
        }
      } catch (e) { } finally {
        this.isUploading = false;
        cb && cb();
      }
    });
  }

  cacheEvent(event) {
    this._events_cache.push(event);
    if (this._events_add_timer) {
      clearTimeout(this._events_add_timer);
    }
    return new Promise((resolve, reject) => {
      this._events_add_timer = setTimeout(() => {
        const events = this._events_cache.splice(0, this._events_cache.length);
        this.storage.add(events)
          .then(() => {
            resolve();
            clearTimeout(this._events_add_timer);
          })
          .catch(reject);
      }, this.options.eventsAddInterval);
    });
  }

  async checkNeedUpload() {
    const { maxCountPerBatch } = this.options;
    const cachedCount = await this.storage.count();
    if (cachedCount >= maxCountPerBatch) {
      return true;
    }
    return false;
  }

  async track(item, { forceUpload = false } = {}) {
    const { isEnabled } = this.options;
    if (!isEnabled) {
      return;
    }

    const event = this.getEvent(item);

    // 实时上传.
    if (forceUpload) {
      this.send(event);
      return true;
    }

    this.cacheEvent(event)
      .finally(() => {
        this.sendIfNeed();
      });

    return true;
  }
}
