class PerResource {
  constructor({
    dnsCost = 0,
    tcpCost = 0,
    reqTime = null,
    resTime = null,
    resCost = 0,
    resourceCost = 0,
    resourceUrl = '',
    resourceBlockingStatus = '',
    resourceSize = 0,
    httpStatus = 0,
  }) {
    this.dns_cost = dnsCost;
    this.tcp_cost = tcpCost;
    this.req_time = reqTime;
    this.res_time = resTime;
    this.res_cost = resCost;
    this.resource_cost = resourceCost;
    this.resource_url = resourceUrl;
    this.resource_blocking_status = resourceBlockingStatus;
    this.resource_size = resourceSize;
    this.http_status = httpStatus || 0;
  }

  get json() {
    return { ...this };
  }
}

export default PerResource;
